// 6. Import the rest of Bulma with settings.

@import 'settings';
@import '../node_modules/bulma/bulma';

// Some overrides to set a good default for Symfony Forms / Boltforms styles
.boltform {
    & label {
        @extend .label;
        line-height: 2em;
    }

    & input {
        @extend .input;
    }

    & textarea {
        @extend .textarea;
    }

    & select {
        @extend .select;
    }

    & input[type='checkbox'] {
        @extend .checkbox;
    }

    & input[type='radio'] {
        @extend .radio;
    }

    & button {
        @extend .button;
        @extend .is-primary;
    }

}


/* Default pagerfanta styles, for pagination */
.pagination {
}

.pagination a,
.pagination span {
    display: inline-block;
    background: #f4f9fa;
    border: 1px solid #96c4cc;
    color: #2c8898;
    margin-right: .2em;
    padding: .4em .35em;
}

.pagination a {
    text-decoration: none;
}

.pagination a:hover {
    background: #c0dbe0;
    color: #982c61;
}

.pagination .dots {
    border-width: 0;
}

.pagination .current {
    background: #c0dbe0;
    font-weight: bold;
}

.pagination .disabled {
    border-color: #c0dbe0;
    color: #abcfd6;
}
