// Show the current breakpoints in the lower left corner.
$debug-breakpoint: false;

// ------------ Settings for Bulma ---------------

// 1. Import the initial variables
@import "../node_modules/bulma/sass/utilities/initial-variables";
@import "../node_modules/bulma/sass/utilities/functions";

// 2. Set your own initial variables

// Colors
$light: rgb(240, 239, 239);
$dark: #444;
$medium: #777;

// Fonts
$font-sans-serif    : 'Roboto', sans-serif;     // Base font
$font-serif         : 'Bitter', serif;          // Headings

// 3. Set the derived variables

// Option 1: https://color.adobe.com/ims-construction-colors-color-theme-10555392/
$link: #DF1C31;
$info: #1B2A3F;
$primary: #2873A4;
$success: #F1A42C;

// Option 2: https://color.adobe.com/mapa-laranja-color-theme-10556928/
// $primary: #C97B5C;
// $info: #3F3430;
// $link: #3F271D;
// $success: #8B7368;

// Option 3: https://color.adobe.com/WASD-color-theme-10551808/
// $primary: #0D6545;
// $info: #373B3A;
// $link: #0E724E;
// $success: #8B7368;

// Option 4: https://color.adobe.com/Palette-de-couleurs-1-color-theme-10564608/
// $primary: darken(#9FD4F1, 10);
// $info: #9B8D1F;
// $link: #385681;
// $success: #9C8754;

// Option 5: Black & White
// $primary: #444;
// $info: #888;
// $link: #385691;
// $success: #777;

$pre-background: $grey-lighter;
$family-primary: $font-sans-serif;

// Generic variables
$body-background-color: $white;
$body-weight: $weight-light;
$column-gap: 1.5rem;

// Title
$title-color: $black;
$title-weight: $weight-normal;
$title-size: 2rem;
$subtitle-size: 1.5rem;

// Box
$box-radius: 0;
$box-shadow: 0;

// 4. Setup your Custom Colors
$linkedin: #0077b5;
$linkedin-invert: findColorInvert($linkedin);
$twitter: #55acee;
$twitter-invert: findColorInvert($twitter);
$github: #333;
$github-invert: findColorInvert($github);

// 5. Import the rest of the "utilities".
@import "../node_modules/bulma/sass/utilities/all";
